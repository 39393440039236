<template>
  <Popup
    close-on-popstate
    :safe-area-inset-bottom="true"
    v-model:show="show"
    :round="true"
  >
    <div class="tel">
      <!-- 商家服务电话 -->
      <div class="flex align-content"
        v-if="props.customerMobile"
        @click="ontel(props.customerMobile)"
      >
        <div class="icon">
          <img :src="`${$imgUrl.refactor}service_moblie.png`" alt="" srcset="">
        </div>
        <div class="span1">
          <div class="m-f-28">商家服务电话</div>
          <div class="m-f-24 m-c-adad">使用、发货、售后等咨询</div>
          <div class="m-f-24 m-c-adad">服务时间：周一至周日9：00-20：00</div>
        </div>
        <div class="right-icon">
          <img :src="`${$imgUrl.public}right.png`" alt="" srcset="" />
        </div>
      </div>
      <!-- 平台在线客服 -->
      <div class="flex align-content"
        @click="open"
      >
        <div class="icon">
          <img :src="`${$imgUrl.refactor}service_moblie.png`" alt="" srcset="">
        </div>
        <div class="span1">
          <div class="m-f-28">平台在线客服</div>
          <div class="m-f-24 m-c-adad">平台客服在线咨询</div>
        </div>
        <div class="right-icon">
          <img :src="`${$imgUrl.public}right.png`" alt="" srcset="" />
        </div>
      </div>
      <!-- 平台客服热线 -->
      <div class="flex align-content" @click="ontel('151 6835 4721')">
        <div class="icon">
          <img :src="`${$imgUrl.refactor}service_moblie.png`" alt="" srcset="">
        </div>
        <div class="span1">
          <div class="m-f-28">平台客服热线</div>
          <div class="m-f-24 m-c-adad">投诉、售后等咨询</div>
          <div class="m-f-24 m-c-adad">服务时间：周一至周日 9:00~20:00</div>
        </div>
        <div class="right-icon">
          <img :src="`${$imgUrl.public}right.png`" alt="" srcset="" />
        </div>
      </div>
      <div class="tel-foot">*本租赁服务由杭州钉享租信息科技有限公司*提供并承担法律责任</div>
    </div>
  </Popup>
</template>
<script setup>
import { Popup } from 'vant';
import {
  ref, getCurrentInstance, defineExpose, defineProps,
} from 'vue';
/** props */
const props = defineProps({
  customerMobile: String,
});
/** 变量 */
const {
  appContext: {
    config: { globalProperties: { $imgUrl } },
  },
} = getCurrentInstance();
const show = ref(false);
/** 函数 */
const onshow = () => {
  show.value = true;
};
const open = () => {
  window.open('https://cschat.antcloud.com.cn/index.htm?tntInstId=xWC_zsMk&scene=SCE01267069');
};
const ontel = (customerMobile) => {
  window.location.href = `tel://${customerMobile}`;
};
/** 暴露方法 */
defineExpose({
  onshow,
});
</script>
<style lang="scss" scoped>
.tel {
  padding: 32px 32px 32px;
  width: 500px;
  .align-content {
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .right-icon {
    width: 22px;
    height: 22px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .icon {
    width: 84px;
    height: 84px;
    >img {
       @include img;
    }
  }
  &-foot {
    text-align: center;
    color: #dddd;
    width: 93%;
    margin: 0 auto;
  }
}
</style>
